import React, { useState } from 'react';
// import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Header from '../../../common/Header';
import '../FileImage.css';
import { call } from '../../../service/ApiService';

function PersonalFile() {
    const navigate = useNavigate();
    const user = JSON.parse(localStorage.getItem("user"))
    // const [ fileDirectory, setFileDirectory ] = useState([]);
    // const [ userId, setUserId ] = useState(null);
    const [ userDirectoryResponse, setUserDirectoryResponse ] = useState({});

    // useEffect(() => {
        // call("/files/directory", "GET", response)
        // .then((res) => {
        //     setFileDirectory(res);
        // }).catch((error) => alert(error));
    // }, []);

    const [selectedDirectory, setSelectedDirectory] = useState(null);

    const handleDirectoryClick = (directory) => {
        setSelectedDirectory(directory);
        setUserDirectoryResponse({ "userId": user.userId, "userDirectory": selectedDirectory, "type": "personal" });
    };

    const handleContainerClick = (event) => {
        if (event.target.closest('.card') === null) {
            setSelectedDirectory(null);
        }
    };

    const handleDirectoryDoubleClick = (e) => {
        // 더블 클릭 시 수행할 동작 구현
        call("/files/view", "GET", userDirectoryResponse)
        .then((res) => {
            navigate(`/files/personal/view/${selectedDirectory}`, { state: { res }})   
        })
        .catch((error) => alert("error : " + error));
    };

    const changeFolderName = () => {
        if (selectedDirectory == null) {
            alert("폴더를 선택해주세요.");
            return;
        }
        call("/files/change/folder", "POST", selectedDirectory)
        .then((res) => {
            alert("폴더명이 변경되었습니다.")
            user.userDirectory = selectedDirectory
            localStorage.setItem("user", JSON.stringify(user))
            navigator("/files/personal")
        })
    };

    // const [previewImage, setPreviewImage] = useState(null);

    // const handleImageClick = (image) => {
    //     setPreviewImage(image);
    // };
    return(
        <div>
            <Header />
            <div className = "container" style = {{}}>
                <div className = "row">
                    <div className = "col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                        <div className = "row">
                            <div className = "container" style = {{ width: "80%" }}>
                                <div className = "row">
                                    <div className = "col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                        <label>
                                            개인만 접근 가능한 파일입니다.<br />
                                            <span style = {{ color: "red" }}>암호화</span>되어 저장되어있습니다.
                                        </label>
                                    </div>
                                    <div className = "col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                        <Link to = "/files/upload" state = {{ uploadType: "1" }}>
                                            <button className = "btn btn-primary" style = {{ float: "right", fontSize: "23px" }}>+ 업로드</button>
                                        </Link>
                                    </div>
                                </div>
                            </div> 
                            <hr />
                            <div className = "container mt-3" style = {{ width: "80%" }}>
                                <div className = "row" style = {{ width: "100%" }}>
                                    <div className = "col-md-2 col-lg-2 col-xl-2 col-xxl-2">
                                        <button type = "button" className = "btn btn-outline-success" onClick = { changeFolderName }>이름 변경</button>
                                    </div>
                                </div>
                                <div className = "row">
                                    <div 
                                        className = "col-md-12 col-lg-12 col-xl-12 col-xxl-12" 
                                        style = {{ 
                                            height: "55vh", 
                                            overflowY: "auto", 
                                            display: "flex", 
                                            alignItems: "center", 
                                            justifyContent: "center" 
                                        }}
                                        onClick={ handleContainerClick }
                                    >
                                        <div
                                            className="card"
                                            style={{
                                                paddingLeft: "5vw",
                                                paddingRight: "5vw",
                                                paddingTop: "4vh",
                                                paddingBottom: "2vh",
                                                backgroundColor: selectedDirectory === user.userDefaultDirectory ? "#007bff" : "inherit",
                                                color: selectedDirectory === user.userDefaultDirectory ? "white" : "inherit",
                                            }}
                                            onClick={ () => handleDirectoryClick(user.userDefaultDirectory) }
                                            onDoubleClick = { handleDirectoryDoubleClick }
                                        >
                                            <div className = "card-title" style = {{ textAlign: "center", padding: "0", margin: "0" }}>
                                                <img alt = "" src = { require("../../../img/folder/CF_8.ico") } style = {{ width: "10vw" }} />
                                            </div>
                                            <div className = "card-body" style = {{ textAlign: "center" }}>
                                                <label>{ user.userDefaultDirectory }</label>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <div className = "col-md-7 col-lg-7 col-xl-7 col-xxl-7" style = {{ border: "solid 1px black", height: "55vh", overflowY: "auto" }}>
                                        <div className = "row">
                                            <div className = "col-md-1 col-lg-1 col-xl-1 col-xxl-1 image-container" style = {{ width: "5vw", height: "10vh", position: "relative" }}>
                                                <img className = "image" alt = "" src = { require("../../../img/carousel/space1.jpg") } style = {{ objectFit: "cover", width: "100%", height: "100%", position: "absolute", top: 0, left: 0, cursor: "pointer" }} onClick = { () => handleImageClick(require("../../../img/carousel/space1.jpg")) } />
                                            </div>
                                            <div className = "col-md-1 col-lg-1 col-xl-1 col-xxl-1 image-container" style = {{ width: "5vw", height: "10vh", position: "relative" }}>
                                                <img className = "image" alt = "" src = { require("../../../img/carousel/space1.jpg") } style = {{ objectFit: "cover", width: "100%", height: "100%", position: "absolute", top: 0, left: 0, cursor: "pointer" }}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className = "col-md-5 col-lg-5 col-xl-5 col-xxl-5" style = {{ border: "solid 1px blue", height: "316px" }}>
                                    { previewImage && (
                                        <img
                                            src={ previewImage }
                                            alt="Preview"
                                            style={{ 
                                                width: "100%", 
                                                height: "100%", 
                                                objectFit: "contain",
                                                backgroundColor: "gray"
                                            }}
                                        />
                                    )}
                                    </div> */}
                                </div>
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default PersonalFile;