import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../../common/Header';

function Setting() {
    return(
        <div>
            <Header />
            <div className = "container">
                <div className = "container">
                    <div className = "row">
                        <div className = "col-md-8 col-lg-8 offset-md-1 offset-lg-1" style = {{ borderBottom: "solid 1px black", display: "flex", alignItems: "center", width: "80%" }}>
                            <Link to = "/setting/group" style = {{ listStyle: "none", color: "black", textDecoration: "none" }}>그룹세팅</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Setting;