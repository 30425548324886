import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { call } from '../../service/ApiService';

function SignUp() {
    const navigate = useNavigate()
    const [emailError, setEmailError] = useState("");
    const [passwordError, setPasswordError] = useState("");
    const [confirmPasswordError, setConfirmPasswordError] = useState("");
    const [userNameError, setUserNameError] = useState("");
    const [userNickNameError, setUserNickNameError] = useState("");
    const [userBirthError, setUserBirthError] = useState("");
    const [userDirectoryError, setUserDirectoryError] = useState("");
    const [userDto, setUserDto] = useState([]);

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const validatePassword = (password) => {
        const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;
        return passwordRegex.test(password);
    };

    const validateUserName = (name) => {
        const koreanRegex = /^[\u3131-\u3163\uac00-\ud7a3]{2,4}$/;
        return koreanRegex.test(name);
    };

    const validateUserNickname = (nickname) => {
        return nickname.trim() !== "";
    };

    const validateUserBirth = (birth) => {
        const birthRegex = /^\d{6}$/;
        return birthRegex.test(birth);
    };

    const validateUserDirectory = (directory) => {
        return directory.trim() !== "";
    };

    const userDataSetting = (e) => { 
        const { name, value } = e.target;

        if (name === "userId") {
            if (!validateEmail(value)) { setEmailError("이메일 형식으로 입력해야 합니다."); } else { setEmailError(""); }
        }

        if (name === "userPw") {
            if (!validatePassword(value)) {
              setPasswordError("특문 + 숫자 + 영문 8자 이상이어야 합니다.");
            } else {
              setPasswordError("");
            }
          } if (name === "confirmPw") {
            if (value !== userDto.userPw) {
              setConfirmPasswordError("비밀번호를 다시 확인해주세요.");
            } else {
              setConfirmPasswordError("");
            }
          }

        if (name === "userName") {
            if (!validateUserName(value)) { setUserNameError("2~4자의 한글 이름을 입력해주세요."); } else { setUserNameError(""); }
        }

        if (name === "userNickname") {
            if (!validateUserNickname(value)) { setUserNickNameError("생성할 닉네임을 입력하세요."); } else { setUserNickNameError(""); }
        }

        if (name === "userBirthDay") {
            if (!validateUserBirth(value)) { setUserBirthError("숫자 6자리만 입력하세요."); } else { setUserBirthError(""); }
        }

        if (name === "userDirectory") {
            if (!validateUserDirectory(value)) { setUserDirectoryError("생성할 폴더명을 입력하세요."); } else { setUserDirectoryError(""); }
        }
        
        setUserDto({ ...userDto, [name]: value });
    };
    
    const signUpBtn = () => {
        call("/users/signup", "POST", userDto)
        .then((res) => navigate("/user/login"))
    };

    return (
        <div>
            <img className = "img-fluid" alt = "" src = { require('../../img/main/LoginMain.png') } style = {{ width: "100%", height: "100vh", position: "absolute", top: "0", left: "0", zIndex: 0 }}/>
            <div style = {{ backgroundColor: "black", position: "absolute", top: "0", left: "0", width: "100vw", height: "100vh", zIndex: 1, opacity: 0.3 }} />
            <div className = "container" style = {{ display: "flex", justifyContent: "center", alignItems: "center", position: "relative", height: "100vh", width: "80%", zIndex: 3 }}>
                <div className = "card" style = {{ height: "70vh", width: "100%", overflowY: "auto", backgroundColor: "rgba(0, 0, 0, 0.6)" }}>
                    <div className = "row" style = {{ width: "100%" }}>
                        <div className = "col-md-2 col-lg-2 col-xl-2 col-xxl-2 offset-md-3 mt-5" style = {{ textAlign: "center" }}>
                            <label style = {{ textAlign: "center", color: "white" }}>ID</label>
                        </div>
                        <div className = "col-md-4 col-lg-4 col-xl-4 col-xxl-4 mt-5">
                            <input type = "text" className = "form-control" placeholder = "example@example.com" name = "userId" onChange = { userDataSetting } />
                        </div>

                        { emailError && <div className = "col-md-4 col-lg-4 col-xl-4 col-xxl-4 offset-md-5 offset-lg-5 offset-xl-5 offset-xxl-5" style={{ color: "red", textAlign: "center" }}>{ emailError }</div> }

                        <div className = "col-md-2 col-lg-2 col-xl-2 col-xxl-2 offset-md-3 mt-3" style = {{ textAlign: "center" }}>
                            <label style = {{ textAlign: "center", color: "white" }}>Password</label>
                        </div>
                        <div className = "col-md-4 col-lg-4 col-xl-4 col-xxl-4 mt-3">
                            <input type = "text" className = "form-control" placeholder = "영문, 숫자, 특문 포함" name = "userPw" onChange = { userDataSetting } />
                        </div>

                        { passwordError && <div className = "col-md-4 col-lg-4 col-xl-4 col-xxl-4 offset-md-5 offset-lg-5 offset-xl-5 offset-xxl-5" style={{ color: "red", textAlign: "center" }}>{ passwordError }</div> }
                        
                        <div className = "col-md-2 col-lg-2 col-xl-2 col-xxl-2 offset-md-3 mt-3" style = {{ textAlign: "center" }}>
                            <label style = {{ textAlign: "center", color: "white" }}>Password 확인</label>
                        </div>
                        <div className = "col-md-4 col-lg-4 col-xl-4 col-xxl-4 mt-3">
                            <input type = "text" className = "form-control" placeholder = "영문, 숫자, 특문 포함" name = "confirmPw" onChange = { userDataSetting } />
                        </div>

                        { confirmPasswordError && <div className = "col-md-4 col-lg-4 col-xl-4 col-xxl-4 offset-md-5 offset-lg-5 offset-xl-5 offset-xxl-5" style={{ color: "red", textAlign: "center" }}>{ confirmPasswordError }</div> }
                        
                        <div className = "col-md-2 col-lg-2 col-xl-2 col-xxl-2 offset-md-3 mt-3" style = {{ textAlign: "center" }}>
                            <label style = {{ textAlign: "center", color: "white" }}>이름</label>
                        </div>
                        <div className = "col-md-4 col-lg-4 col-xl-4 col-xxl-4 mt-3">
                            <input type = "text" className = "form-control" placeholder = "2 ~ 4자" name = "userName" onChange = { userDataSetting } />
                        </div>

                        { userNameError && <div className = "col-md-4 col-lg-4 col-xl-4 col-xxl-4 offset-md-5 offset-lg-5 offset-xl-5 offset-xxl-5" style={{ color: "red", textAlign: "center" }}>{ userNameError }</div> }
                        
                        <div className = "col-md-2 col-lg-2 col-xl-2 col-xxl-2 offset-md-3 mt-3" style = {{ textAlign: "center" }}>
                            <label style = {{ textAlign: "center", color: "white" }}>닉네임</label>
                        </div>
                        <div className = "col-md-4 col-lg-4 col-xl-4 col-xxl-4 mt-3">
                            <input type = "text" className = "form-control" placeholder = "앱 내에서 보여질 이름" name = "userNickname" onChange = { userDataSetting } />
                        </div>

                        { userNickNameError && <div className = "col-md-4 col-lg-4 col-xl-4 col-xxl-4 offset-md-5 offset-lg-5 offset-xl-5 offset-xxl-5" style={{ color: "red", textAlign: "center" }}>{ userNickNameError }</div> }

                        <div className = "col-md-2 col-lg-2 col-xl-2 col-xxl-2 offset-md-3 mt-3" style = {{ textAlign: "center" }}>
                            <label style = {{ textAlign: "center", color: "white" }}>생년월일</label>
                        </div>
                        <div className = "col-md-2 col-lg-2 col-xl-2 col-xxl-2 mt-3">
                            <div className = "row">
                                <div className = "col-md-11 col-lg-11 col-xl-11 col-xxl-11">
                                    <input type = "text" className = "form-control" style = {{ width: "100%" }} name = "userBirthDay" onChange = { userDataSetting } placeholder = "000101" />
                                </div>
                                <div className = "col-md-1 col-lg-1 col-xl-1 col-xxl-1 gx-0">
                                    <label style = {{ fontSize: "0.8rem", color: "white" }}> - </label>
                                </div>
                            </div>
                        </div>
                        <div className = "col-md-2 col-lg-2 col-xl-2 col-xxl-2 mt-3 gx-0">
                            <div className = "row">
                                <div className = "col-md-4 col-lg-4 col-xl-4 col-xxl-4">
                                    <input type = "text" className = "form-control" style = {{ width: "100%", textAlign: "center" }} name = "userGender" onChange = { userDataSetting } />
                                </div>
                                <div className = "col-md-8 col-lg-8 col-xl-8 col-xxl-8 gx-0">
                                    <label style = {{ fontSize: "0.8rem", color: "white" }}>● ● ● ● ● ●</label>
                                </div>
                            </div>
                        </div>
                        
                        { userBirthError && <div className = "col-md-4 col-lg-4 col-xl-4 col-xxl-4 offset-md-5 offset-lg-5 offset-xl-5 offset-xxl-5" style={{ color: "red", textAlign: "center" }}>{ userBirthError }</div> }
                        
                        <div className = "col-md-2 col-lg-2 col-xl-2 col-xxl-2 offset-md-3 mt-3" style = {{ textAlign: "center" }}>
                            <label style = {{ textAlign: "center", color: "white" }}>저장경로 명 생성</label>
                        </div>
                        <div className = "col-md-4 col-lg-4 col-xl-4 col-xxl-4 mt-3">
                            <input type = "text" className = "form-control" placeholder = "나만의 저장경로 명" name = "userDirectory" onChange = { userDataSetting } /> 
                        </div>

                        { userDirectoryError && <div className = "col-md-4 col-lg-4 col-xl-4 col-xxl-4 offset-md-5 offset-lg-5 offset-xl-5 offset-xxl-5" style={{ color: "red", textAlign: "center" }}>{ userDirectoryError }</div> }
                        
                        <div className = "col-md-6 col-lg-6 col-xl-6 col-xxl-6 offset-md-3 mt-3 mb-5">
                            <button type = "button" className = "btn btn-outline-info" style = {{ width: "100%" }} onClick = { signUpBtn }>가입</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default SignUp;
