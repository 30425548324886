import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import App from './App';
import SignUp from './page/user/SignUp';
import Login from './page/user/Login';
import GroupSetting from './page/setting/group/GroupSetting';
import Setting from './page/setting/Setting';
import Promotion from './page/promotion/Promotion';
import NearStorageMain from './page/NearStorageMain';
import PersonalFile from './page/sharefile/personal/PersonalFile';
import FileUpload from './page/sharefile/FileUpload';
import GroupFile from './page/sharefile/group/GroupFile';
import ViewDirectory from './page/sharefile/ViewDirectory';

function AppRoute() {
    return (
        <Router>
            <Routes>
                <Route path = "/" element = { <App /> } />

                {/* Promotion */}
                <Route path = "/promotion" element = { <Promotion /> } />

                {/* Main */}
                <Route path = "/near-storage" element = { <NearStorageMain /> } />

                {/* User */}
                <Route path = "/user/signup" element = { <SignUp /> } />
                <Route path = "/user/login" element = { <Login /> } />

                {/* File */}
                <Route path = "/files/personal" element = { <PersonalFile /> } />
                <Route path = "/files/group" element = { <GroupFile /> } />
                <Route path = "/files/upload" element = { <FileUpload /> } />
                <Route path = "/files/personal/view/:id" element = { <ViewDirectory /> } />

                {/* Setting */}
                <Route path = "/setting" element = { <Setting /> } />
                <Route path = "/setting/group" element = { <GroupSetting /> } />
            </Routes>
        </Router>
    )
}
export default AppRoute;
