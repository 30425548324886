import React from 'react';
import Sidebar from '../page/Sidebar';
import { Link } from 'react-router-dom';
// import { useLocation } from 'react-router-dom';

function Header() {
    // const location = useLocation();
    // const shareFile = [ "/share-file/personalFile", "/share-file/personal-file/upload" ];
    // const setting = [ "/setting", "/setting/group" ];


    return(
        <header style = {{ marginBottom: "15px" }}>
            <div className = "" style = {{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                <div className = "row" style = {{ verticalAlign: "middle", width: "100%", marginTop: "3vh" }}>
                    <div className = "col-md-1 col-lg-1 col-xl-1 col-xxl-1" style = {{ display: "flex", alignItems: "center" }}>
                        <Sidebar />
                    </div>
                    <div className = "col-md-4 col-lg-4 col-xl-4 col-xxl-4 offset-md-3 offset-lg-3 offset-xl-3 offset-xxl-3" style = {{ textAlign: "center" }}>
                        <Link to = "/near-storage"><img alt = "" src = { require("../img/main/NearStorageLogo.png") } style = {{ width: "20%" }} /></Link>
                    </div>
                </div>
            </div>
        </header>
    );
}
export default Header;