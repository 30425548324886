import React, { useState } from 'react';
import { call } from '../../service/ApiService';
import { useNavigate } from 'react-router-dom';

function Login() {
    const navigate = useNavigate();
    const [loginDto, setLoginDto] = useState([]);

    const sendLogin = (e) => {
        if ([e.target.name].includes("email")) setLoginDto({ ...loginDto, [e.target.name]: e.target.value });
        else setLoginDto({ ...loginDto, [e.target.name]: e.target.value });
    };
    const sendLoginData = () => {
        call("/users/login", "POST", loginDto)
        .then((res) => {
            localStorage.setItem("Access_Token", (res.userToken));
            localStorage.setItem("user", JSON.stringify(res));

            if(localStorage.getItem("Access_Token") !== null) {
                navigate("/near-storage");
            }
        }).catch((error) => { alert(error.status) });
    };

    const naviSignup = () => {
        navigate("/user/signup");
    };

    return (
        <div>
            <img className = "img-fluid" alt = "" src = { require('../../img/main/LoginMain.png') } style = {{ width: "100%", height: "100vh", position: "absolute", top: "0", left: "0", zIndex: 0 }}/>
            <div style = {{ backgroundColor: "black", position: "absolute", top: "0", left: "0", width: "100vw", height: "100vh", zIndex: 1, opacity: 0.3 }} />
            <div className = "container" style = {{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh", position: "relative", zIndex: 2 }}>
                <div className = "row" style = {{ width: "100%" }}>
                    <div className = "col-md-9 col-lg-9 col-xxl-8 gx-0" style = {{  }} />
                    <div className = "col-md-3 col-lg-3 col-xxl-4 card gx-0" style = {{ backgroundColor: "rgba(0, 0, 0, 0.6)" }}>
                        <div className = "card-title">
                            <img alt = "" src = { require("../../img/main/Near_Login.png") } style = {{ width: "100%", borderTopLeftRadius: 5, borderTopRightRadius: 5 }} />
                        </div>
                        <div className = "card-body" style = {{ display: "flex", flexDirection: "column", justifyContent: "flex-end" }}>
                            <div className = "row">
                                <div className = "col-md-12 col-lg-12" style = {{ paddingRight: "1vw" }}>
                                    <input type = "text" className = "form-control" name = "email" onChange = { sendLogin } placeholder = "ID"/>
                                </div>
                                <div className = "col-md-12 col-lg-12 mt-2" style = {{ paddingRight: "1vw" }}>
                                    <input type = "password" className = "form-control" name = "password" onChange = { sendLogin } placeholder = "PASSWORD" onKeyDown = { (e) => {if (e.key === 'Enter') { sendLoginData(); }}} />
                                </div>
                                <div className = "col-md-12 col-lg-12 mt-2 d-grid gap-2">
                                    <button type = "button" className = "btn btn-outline-info" stlye = {{ width: "100%" }} onClick = { sendLoginData }>로그인</button>
                                </div>
                                <div className = "col-md-12 col-lg-12 mt-2 d-grid gap-2">
                                    <button type = "button" className = "btn btn-outline-warning" stlye = {{ width: "100%" }} onClick = { naviSignup }>Near 회원가입</button>
                                </div>
                                <div className = "col-md-12 col-lg-12 mt-2">
                                    <div className = "row">
                                        <div className = "col-md-4 col-lg-4" style = {{ border: "solid 1px black", textAlign: "center" }}>kakao</div>
                                        <div className = "col-md-4 col-lg-4" style = {{ border: "solid 1px black", textAlign: "center" }}>google</div>
                                        <div className = "col-md-4 col-lg-4" style = {{ border: "solid 1px black", textAlign: "center" }}>naver</div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default Login;
