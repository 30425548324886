import 'bootstrap/dist/css/bootstrap.css'
import './App.css'
import Promotion from './page/promotion/Promotion';
// import Login from './page/user/Login';

function App() {

  return (
    <div className = "App" style = {{  }}>
      <Promotion />
    </div>
  );
}

export default App;
