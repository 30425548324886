import React, { useEffect, useState } from 'react';
import { Carousel } from 'react-bootstrap';

const MyCarousel = () => {
    const [imageSize, setImageSize] = useState({ height: 0, width: 0 });

    useEffect(() => {
        const handleResize = () => {
        const windowWidth = window.innerWidth;
        const windowHeight = window.innerHeight;
        setImageSize({ 
            height: windowHeight * 0.45,
            width: windowWidth * 1
        }); // 이미지 크기를 브라우저 창 크기의 80%로 설정
        };
    
        window.addEventListener('resize', handleResize);
        handleResize(); // 초기 렌더링 시 이미지 크기 설정
    
        return () => {
        window.removeEventListener('resize', handleResize);
        };
    }, []);

    return (
        <Carousel id="carouselExampleIndicators">
            <Carousel.Item>
                <img className="d-block w-100" src={require('../../img/carousel/space1.jpg')} alt="First slide" style={{ height: `${imageSize.height}px`, width: `${imageSize.width}px`, objectFit: "contain", backgroundColor: "#E6E6E6" }} />
            </Carousel.Item>
            <Carousel.Item>
                <img className="d-block w-100" src={require('../../img/carousel/space2.jpg')} alt="Second slide" style={{ height: `${imageSize.height}px`, width: `${imageSize.width}px`, objectFit: "contain", backgroundColor: "#E6E6E6" }} />
            </Carousel.Item>
            <Carousel.Item>
                <img className="d-block w-100" src={require('../../img/carousel/space3.jpg')} alt="Third slide" style={{ height: `${imageSize.height}px`, width: `${imageSize.width}px`, objectFit: "contain", backgroundColor: "#E6E6E6" }} />
            </Carousel.Item>
        </Carousel>
    );
};

export default MyCarousel;