import React, { useEffect, useRef } from 'react';
import './Promotion.css';
import { Link } from 'react-router-dom';
import PromotionCarousel from './Carousel';

function Promotion() {
    const containerRef = useRef(null);

    useEffect(() => {
        const handleScroll = () => {
            const container = containerRef.current;
            if (container) {
                const images = container.querySelectorAll('.slide-in');
                images.forEach((img) => {
                    const rect = img.getBoundingClientRect();
                    if (rect.top >= 0 && rect.bottom <= window.innerHeight) {
                        img.classList.add('show');
                    }
                });
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return(
        <div style = {{ height: "100vh" }}>
            <div className = "container" style = {{ width: "60%" }}>
                <div className = "col-md-2 col-lg-2 offset-md-10 offset-lg-10" style = {{ marginTop: "2vh", marginBottom: "1vh" }}>
                    <Link to = "/user/login">
                        <button type = "button" className = "btn btn-primary">
                            시작하기
                            <span><img alt = "" src = { require("../../img/main/RightTopArrowLogo.png") } style = {{ width: "20%", height: "20%", marginLeft: "5px", paddingBottom: "2px" }} /></span>
                        </button>
                    </Link>
                </div>
            </div>
            <PromotionCarousel />
            <div style = {{ marginTop: "30vh", paddingBottom: "10vh" }} ref={ containerRef }>
                <div className = "container" style = {{ width: "60%" }}>
                    <div style = {{ textAlign: "center" }}>
                        <img alt = "" src = { require("../../img/promotion/promotion_1.png") } style = {{ width: "100%" }} className="slide-in" />
                    </div>
                    <div style = {{ textAlign: "center", marginTop: "40vh" }}>
                        <img alt = "" src = { require("../../img/promotion/promotion_2.png") } style = {{ width: "100%" }} className="slide-in" />
                    </div>
                    <div style = {{ textAlign: "center", marginTop: "40vh" }}>
                        <img alt = "" src = { require("../../img/promotion/promotion_3.png") } style = {{ width: "100%" }} className="slide-in" />
                    </div>
                    <div style = {{ textAlign: "center", marginTop: "40vh" }}>
                        <img alt = "" src = { require("../../img/promotion/promotion_4.png") } style = {{ width: "100%" }} className="slide-in" />
                    </div>
                </div>
            </div>
            <div className = "container" style = {{ width: "60%", paddingBottom: "10vh" }}>
                <div className = "col-md-8 col-lg-8 col-xl-8 col-xxl-8 offset-md-2 offset-lg-2 offset-xl-2 offset-xxl-2" style = {{ marginTop: "2vh", marginBottom: "1vh" }}>
                    <Link to = "/user/login">
                        <button type = "button" className = "btn btn-outline-primary" style = {{ width: "100%" }}>
                            <span style = {{ fontSize: "3.5rem" }}>시작하기</span>
                            <span><img alt = "" src = { require("../../img/main/RightTopArrowLogo.png") } style = {{ width: "10%", height: "10%", marginLeft: "5px", paddingBottom: "2px" }} /></span>
                        </button>
                    </Link>
                </div>
            </div>
        </div>
    );
}
export default Promotion;