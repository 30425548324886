import React from 'react';
import Header from '../../../common/Header';
import { Link } from 'react-router-dom';

function GroupFile() {
    return (
        <div>
            <Header />
            <div className = "container" style = {{}}>
                <div className = "row">
                    <div className = "col-md-12 col-lg-12 col-xl-12 col-xxl-12">
                        <div className = "row">
                            <div className = "container" style = {{ width: "80%" }}>
                                <div className = "row">
                                    <div className = "col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                        <label>
                                            그룹에 속한 사람만 접근 가능한 파일입니다.<br />
                                            <span style = {{ color: "red" }}>암호화</span>되어 저장되어있습니다.
                                        </label>
                                    </div>
                                    <div className = "col-md-6 col-lg-6 col-xl-6 col-xxl-6">
                                        <Link to = "/files/upload" state = {{ uploadType: "2" }}>
                                            <button className = "btn btn-primary" style = {{ float: "right", fontSize: "23px" }}>+ 업로드</button>
                                        </Link>
                                    </div>
                                </div>
                            </div> 
                            <div className = "container mt-3" style = {{ width: "100%" }}>
                                <div className = "row">
                                    <div className = "col-md-7 col-lg-7 col-xl-7 col-xxl-7 offset-md-1 offset-lg-1 offset-xl-1 offset-xxl-1" style = {{ border: "solid 1px black", height: "55vh", overflowY: "auto" }}>
                                        <div className = "row">
                                            <div className = "col-md-1 col-lg-1 col-xl-1 col-xxl-1 image-container" style = {{ width: "5vw", height: "10vh", position: "relative" }}>
                                                <img className = "image" alt = "" src = { require("../../../img/carousel/space1.jpg") } style = {{ objectFit: "cover", width: "100%", height: "100%", position: "absolute", top: 0, left: 0, cursor: "pointer" }}/>
                                            </div>
                                            <div className = "col-md-1 col-lg-1 col-xl-1 col-xxl-1 image-container" style = {{ width: "5vw", height: "10vh", position: "relative" }}>
                                                <img className = "image" alt = "" src = { require("../../../img/carousel/space1.jpg") } style = {{ objectFit: "cover", width: "100%", height: "100%", position: "absolute", top: 0, left: 0, cursor: "pointer" }}/>
                                            </div>
                                            <div className = "col-md-1 col-lg-1 col-xl-1 col-xxl-1 image-container" style = {{ width: "5vw", height: "10vh", position: "relative" }}>
                                                <img className = "image" alt = "" src = { require("../../../img/carousel/space1.jpg") } style = {{ objectFit: "cover", width: "100%", height: "100%", position: "absolute", top: 0, left: 0, cursor: "pointer" }}/>
                                            </div>
                                            <div className = "col-md-1 col-lg-1 col-xl-1 col-xxl-1 image-container" style = {{ width: "5vw", height: "10vh", position: "relative" }}>
                                                <img className = "image" alt = "" src = { require("../../../img/carousel/space1.jpg") } style = {{ objectFit: "cover", width: "100%", height: "100%", position: "absolute", top: 0, left: 0, cursor: "pointer" }}/>
                                            </div>
                                            <div className = "col-md-1 col-lg-1 col-xl-1 col-xxl-1 image-container" style = {{ width: "5vw", height: "10vh", position: "relative" }}>
                                                <img className = "image" alt = "" src = { require("../../../img/carousel/space1.jpg") } style = {{ objectFit: "cover", width: "100%", height: "100%", position: "absolute", top: 0, left: 0, cursor: "pointer" }}/>
                                            </div>
                                            <div className = "col-md-1 col-lg-1 col-xl-1 col-xxl-1 image-container" style = {{ width: "5vw", height: "10vh", position: "relative" }}>
                                                <img className = "image" alt = "" src = { require("../../../img/carousel/space1.jpg") } style = {{ objectFit: "cover", width: "100%", height: "100%", position: "absolute", top: 0, left: 0, cursor: "pointer" }}/>
                                            </div>
                                            <div className = "col-md-1 col-lg-1 col-xl-1 col-xxl-1 image-container" style = {{ width: "5vw", height: "10vh", position: "relative" }}>
                                                <img className = "image" alt = "" src = { require("../../../img/carousel/space1.jpg") } style = {{ objectFit: "cover", width: "100%", height: "100%", position: "absolute", top: 0, left: 0, cursor: "pointer" }}/>
                                            </div>
                                            <div className = "col-md-1 col-lg-1 col-xl-1 col-xxl-1 image-container" style = {{ width: "5vw", height: "10vh", position: "relative" }}>
                                                <img className = "image" alt = "" src = { require("../../../img/carousel/space1.jpg") } style = {{ objectFit: "cover", width: "100%", height: "100%", position: "absolute", top: 0, left: 0, cursor: "pointer" }}/>
                                            </div>
                                            <div className = "col-md-1 col-lg-1 col-xl-1 col-xxl-1 image-container" style = {{ width: "5vw", height: "10vh", position: "relative" }}>
                                                <img className = "image" alt = "" src = { require("../../../img/carousel/space1.jpg") } style = {{ objectFit: "cover", width: "100%", height: "100%", position: "absolute", top: 0, left: 0, cursor: "pointer" }}/>
                                            </div>
                                            <div className = "col-md-1 col-lg-1 col-xl-1 col-xxl-1 image-container" style = {{ width: "5vw", height: "10vh", position: "relative" }}>
                                                <img className = "image" alt = "" src = { require("../../../img/carousel/space1.jpg") } style = {{ objectFit: "cover", width: "100%", height: "100%", position: "absolute", top: 0, left: 0, cursor: "pointer" }}/>
                                            </div>
                                            <div className = "col-md-1 col-lg-1 col-xl-1 col-xxl-1 image-container" style = {{ width: "5vw", height: "10vh", position: "relative" }}>
                                                <img className = "image" alt = "" src = { require("../../../img/carousel/space1.jpg") } style = {{ objectFit: "cover", width: "100%", height: "100%", position: "absolute", top: 0, left: 0, cursor: "pointer" }}/>
                                            </div>
                                            <div className = "col-md-1 col-lg-1 col-xl-1 col-xxl-1 image-container" style = {{ width: "5vw", height: "10vh", position: "relative" }}>
                                                <img className = "image" alt = "" src = { require("../../../img/carousel/space1.jpg") } style = {{ objectFit: "cover", width: "100%", height: "100%", position: "absolute", top: 0, left: 0, cursor: "pointer" }}/>
                                            </div>
                                            <div className = "col-md-1 col-lg-1 col-xl-1 col-xxl-1 image-container" style = {{ width: "5vw", height: "10vh", position: "relative" }}>
                                                <img className = "image" alt = "" src = { require("../../../img/carousel/space1.jpg") } style = {{ objectFit: "cover", width: "100%", height: "100%", position: "absolute", top: 0, left: 0, cursor: "pointer" }}/>
                                            </div>
                                            <div className = "col-md-1 col-lg-1 col-xl-1 col-xxl-1 image-container" style = {{ width: "5vw", height: "10vh", position: "relative" }}>
                                                <img className = "image" alt = "" src = { require("../../../img/carousel/space1.jpg") } style = {{ objectFit: "cover", width: "100%", height: "100%", position: "absolute", top: 0, left: 0, cursor: "pointer" }}/>
                                            </div>
                                            <div className = "col-md-1 col-lg-1 col-xl-1 col-xxl-1 image-container" style = {{ width: "5vw", height: "10vh", position: "relative" }}>
                                                <img className = "image" alt = "" src = { require("../../../img/carousel/space1.jpg") } style = {{ objectFit: "cover", width: "100%", height: "100%", position: "absolute", top: 0, left: 0, cursor: "pointer" }}/>
                                            </div>
                                            <div className = "col-md-1 col-lg-1 col-xl-1 col-xxl-1 image-container" style = {{ width: "5vw", height: "10vh", position: "relative" }}>
                                                <img className = "image" alt = "" src = { require("../../../img/carousel/space1.jpg") } style = {{ objectFit: "cover", width: "100%", height: "100%", position: "absolute", top: 0, left: 0, cursor: "pointer" }}/>
                                            </div>
                                            <div className = "col-md-1 col-lg-1 col-xl-1 col-xxl-1 image-container" style = {{ width: "5vw", height: "10vh", position: "relative" }}>
                                                <img className = "image" alt = "" src = { require("../../../img/carousel/space1.jpg") } style = {{ objectFit: "cover", width: "100%", height: "100%", position: "absolute", top: 0, left: 0, cursor: "pointer" }}/>
                                            </div>
                                            <div className = "col-md-1 col-lg-1 col-xl-1 col-xxl-1 image-container" style = {{ width: "5vw", height: "10vh", position: "relative" }}>
                                                <img className = "image" alt = "" src = { require("../../../img/carousel/space1.jpg") } style = {{ objectFit: "cover", width: "100%", height: "100%", position: "absolute", top: 0, left: 0, cursor: "pointer" }}/>
                                            </div>
                                            <div className = "col-md-1 col-lg-1 col-xl-1 col-xxl-1 image-container" style = {{ width: "5vw", height: "10vh", position: "relative" }}>
                                                <img className = "image" alt = "" src = { require("../../../img/carousel/space1.jpg") } style = {{ objectFit: "cover", width: "100%", height: "100%", position: "absolute", top: 0, left: 0, cursor: "pointer" }}/>
                                            </div>
                                            <div className = "col-md-1 col-lg-1 col-xl-1 col-xxl-1 image-container" style = {{ width: "5vw", height: "10vh", position: "relative" }}>
                                                <img className = "image" alt = "" src = { require("../../../img/carousel/space1.jpg") } style = {{ objectFit: "cover", width: "100%", height: "100%", position: "absolute", top: 0, left: 0, cursor: "pointer" }}/>
                                            </div>
                                            <div className = "col-md-1 col-lg-1 col-xl-1 col-xxl-1 image-container" style = {{ width: "5vw", height: "10vh", position: "relative" }}>
                                                <img className = "image" alt = "" src = { require("../../../img/carousel/space1.jpg") } style = {{ objectFit: "cover", width: "100%", height: "100%", position: "absolute", top: 0, left: 0, cursor: "pointer" }}/>
                                            </div>
                                            <div className = "col-md-1 col-lg-1 col-xl-1 col-xxl-1 image-container" style = {{ width: "5vw", height: "10vh", position: "relative" }}>
                                                <img className = "image" alt = "" src = { require("../../../img/carousel/space1.jpg") } style = {{ objectFit: "cover", width: "100%", height: "100%", position: "absolute", top: 0, left: 0, cursor: "pointer" }}/>
                                            </div>
                                            <div className = "col-md-1 col-lg-1 col-xl-1 col-xxl-1 image-container" style = {{ width: "5vw", height: "10vh", position: "relative" }}>
                                                <img className = "image" alt = "" src = { require("../../../img/carousel/space1.jpg") } style = {{ objectFit: "cover", width: "100%", height: "100%", position: "absolute", top: 0, left: 0, cursor: "pointer" }}/>
                                            </div>
                                            <div className = "col-md-1 col-lg-1 col-xl-1 col-xxl-1 image-container" style = {{ width: "5vw", height: "10vh", position: "relative" }}>
                                                <img className = "image" alt = "" src = { require("../../../img/carousel/space1.jpg") } style = {{ objectFit: "cover", width: "100%", height: "100%", position: "absolute", top: 0, left: 0, cursor: "pointer" }}/>
                                            </div>
                                            <div className = "col-md-1 col-lg-1 col-xl-1 col-xxl-1 image-container" style = {{ width: "5vw", height: "10vh", position: "relative" }}>
                                                <img className = "image" alt = "" src = { require("../../../img/carousel/space1.jpg") } style = {{ objectFit: "cover", width: "100%", height: "100%", position: "absolute", top: 0, left: 0, cursor: "pointer" }}/>
                                            </div>
                                            <div className = "col-md-1 col-lg-1 col-xl-1 col-xxl-1 image-container" style = {{ width: "5vw", height: "10vh", position: "relative" }}>
                                                <img className = "image" alt = "" src = { require("../../../img/carousel/space1.jpg") } style = {{ objectFit: "cover", width: "100%", height: "100%", position: "absolute", top: 0, left: 0, cursor: "pointer" }}/>
                                            </div>
                                            <div className = "col-md-1 col-lg-1 col-xl-1 col-xxl-1 image-container" style = {{ width: "5vw", height: "10vh", position: "relative" }}>
                                                <img className = "image" alt = "" src = { require("../../../img/carousel/space1.jpg") } style = {{ objectFit: "cover", width: "100%", height: "100%", position: "absolute", top: 0, left: 0, cursor: "pointer" }}/>
                                            </div>
                                            <div className = "col-md-1 col-lg-1 col-xl-1 col-xxl-1 image-container" style = {{ width: "5vw", height: "10vh", position: "relative" }}>
                                                <img className = "image" alt = "" src = { require("../../../img/carousel/space1.jpg") } style = {{ objectFit: "cover", width: "100%", height: "100%", position: "absolute", top: 0, left: 0, cursor: "pointer" }}/>
                                            </div>
                                            <div className = "col-md-1 col-lg-1 col-xl-1 col-xxl-1 image-container" style = {{ width: "5vw", height: "10vh", position: "relative" }}>
                                                <img className = "image" alt = "" src = { require("../../../img/carousel/space1.jpg") } style = {{ objectFit: "cover", width: "100%", height: "100%", position: "absolute", top: 0, left: 0, cursor: "pointer" }}/>
                                            </div>
                                            <div className = "col-md-1 col-lg-1 col-xl-1 col-xxl-1 image-container" style = {{ width: "5vw", height: "10vh", position: "relative" }}>
                                                <img className = "image" alt = "" src = { require("../../../img/carousel/space1.jpg") } style = {{ objectFit: "cover", width: "100%", height: "100%", position: "absolute", top: 0, left: 0, cursor: "pointer" }}/>
                                            </div>
                                            <div className = "col-md-1 col-lg-1 col-xl-1 col-xxl-1 image-container" style = {{ width: "5vw", height: "10vh", position: "relative" }}>
                                                <img className = "image" alt = "" src = { require("../../../img/carousel/space1.jpg") } style = {{ objectFit: "cover", width: "100%", height: "100%", position: "absolute", top: 0, left: 0, cursor: "pointer" }}/>
                                            </div>
                                            <div className = "col-md-1 col-lg-1 col-xl-1 col-xxl-1 image-container" style = {{ width: "5vw", height: "10vh", position: "relative" }}>
                                                <img className = "image" alt = "" src = { require("../../../img/carousel/space1.jpg") } style = {{ objectFit: "cover", width: "100%", height: "100%", position: "absolute", top: 0, left: 0, cursor: "pointer" }}/>
                                            </div>
                                            <div className = "col-md-1 col-lg-1 col-xl-1 col-xxl-1 image-container" style = {{ width: "5vw", height: "10vh", position: "relative" }}>
                                                <img className = "image" alt = "" src = { require("../../../img/carousel/space1.jpg") } style = {{ objectFit: "cover", width: "100%", height: "100%", position: "absolute", top: 0, left: 0, cursor: "pointer" }}/>
                                            </div>
                                            <div className = "col-md-1 col-lg-1 col-xl-1 col-xxl-1 image-container" style = {{ width: "5vw", height: "10vh", position: "relative" }}>
                                                <img className = "image" alt = "" src = { require("../../../img/carousel/space1.jpg") } style = {{ objectFit: "cover", width: "100%", height: "100%", position: "absolute", top: 0, left: 0, cursor: "pointer" }}/>
                                            </div>
                                            <div className = "col-md-1 col-lg-1 col-xl-1 col-xxl-1 image-container" style = {{ width: "5vw", height: "10vh", position: "relative" }}>
                                                <img className = "image" alt = "" src = { require("../../../img/carousel/space1.jpg") } style = {{ objectFit: "cover", width: "100%", height: "100%", position: "absolute", top: 0, left: 0, cursor: "pointer" }}/>
                                            </div>
                                            <div className = "col-md-1 col-lg-1 col-xl-1 col-xxl-1 image-container" style = {{ width: "5vw", height: "10vh", position: "relative" }}>
                                                <img className = "image" alt = "" src = { require("../../../img/carousel/space1.jpg") } style = {{ objectFit: "cover", width: "100%", height: "100%", position: "absolute", top: 0, left: 0, cursor: "pointer" }}/>
                                            </div>
                                            <div className = "col-md-1 col-lg-1 col-xl-1 col-xxl-1 image-container" style = {{ width: "5vw", height: "10vh", position: "relative" }}>
                                                <img className = "image" alt = "" src = { require("../../../img/carousel/space1.jpg") } style = {{ objectFit: "cover", width: "100%", height: "100%", position: "absolute", top: 0, left: 0, cursor: "pointer" }}/>
                                            </div>
                                            <div className = "col-md-1 col-lg-1 col-xl-1 col-xxl-1 image-container" style = {{ width: "5vw", height: "10vh", position: "relative" }}>
                                                <img className = "image" alt = "" src = { require("../../../img/carousel/space1.jpg") } style = {{ objectFit: "cover", width: "100%", height: "100%", position: "absolute", top: 0, left: 0, cursor: "pointer" }}/>
                                            </div>
                                            <div className = "col-md-1 col-lg-1 col-xl-1 col-xxl-1 image-container" style = {{ width: "5vw", height: "10vh", position: "relative" }}>
                                                <img className = "image" alt = "" src = { require("../../../img/carousel/space1.jpg") } style = {{ objectFit: "cover", width: "100%", height: "100%", position: "absolute", top: 0, left: 0, cursor: "pointer" }}/>
                                            </div>
                                            <div className = "col-md-1 col-lg-1 col-xl-1 col-xxl-1 image-container" style = {{ width: "5vw", height: "10vh", position: "relative" }}>
                                                <img className = "image" alt = "" src = { require("../../../img/carousel/space1.jpg") } style = {{ objectFit: "cover", width: "100%", height: "100%", position: "absolute", top: 0, left: 0, cursor: "pointer" }}/>
                                            </div>
                                            <div className = "col-md-1 col-lg-1 col-xl-1 col-xxl-1 image-container" style = {{ width: "5vw", height: "10vh", position: "relative" }}>
                                                <img className = "image" alt = "" src = { require("../../../img/carousel/space1.jpg") } style = {{ objectFit: "cover", width: "100%", height: "100%", position: "absolute", top: 0, left: 0, cursor: "pointer" }}/>
                                            </div>
                                            <div className = "col-md-1 col-lg-1 col-xl-1 col-xxl-1 image-container" style = {{ width: "5vw", height: "10vh", position: "relative" }}>
                                                <img className = "image" alt = "" src = { require("../../../img/carousel/space1.jpg") } style = {{ objectFit: "cover", width: "100%", height: "100%", position: "absolute", top: 0, left: 0, cursor: "pointer" }}/>
                                            </div>
                                            <div className = "col-md-1 col-lg-1 col-xl-1 col-xxl-1 image-container" style = {{ width: "5vw", height: "10vh", position: "relative" }}>
                                                <img className = "image" alt = "" src = { require("../../../img/carousel/space1.jpg") } style = {{ objectFit: "cover", width: "100%", height: "100%", position: "absolute", top: 0, left: 0, cursor: "pointer" }}/>
                                            </div>
                                            <div className = "col-md-1 col-lg-1 col-xl-1 col-xxl-1 image-container" style = {{ width: "5vw", height: "10vh", position: "relative" }}>
                                                <img className = "image" alt = "" src = { require("../../../img/carousel/space1.jpg") } style = {{ objectFit: "cover", width: "100%", height: "100%", position: "absolute", top: 0, left: 0, cursor: "pointer" }}/>
                                            </div>
                                            <div className = "col-md-1 col-lg-1 col-xl-1 col-xxl-1 image-container" style = {{ width: "5vw", height: "10vh", position: "relative" }}>
                                                <img className = "image" alt = "" src = { require("../../../img/carousel/space1.jpg") } style = {{ objectFit: "cover", width: "100%", height: "100%", position: "absolute", top: 0, left: 0, cursor: "pointer" }}/>
                                            </div>
                                            <div className = "col-md-1 col-lg-1 col-xl-1 col-xxl-1 image-container" style = {{ width: "5vw", height: "10vh", position: "relative" }}>
                                                <img className = "image" alt = "" src = { require("../../../img/carousel/space1.jpg") } style = {{ objectFit: "cover", width: "100%", height: "100%", position: "absolute", top: 0, left: 0, cursor: "pointer" }}/>
                                            </div>
                                            <div className = "col-md-1 col-lg-1 col-xl-1 col-xxl-1 image-container" style = {{ width: "5vw", height: "10vh", position: "relative" }}>
                                                <img className = "image" alt = "" src = { require("../../../img/carousel/space1.jpg") } style = {{ objectFit: "cover", width: "100%", height: "100%", position: "absolute", top: 0, left: 0, cursor: "pointer" }}/>
                                            </div>
                                            <div className = "col-md-1 col-lg-1 col-xl-1 col-xxl-1 image-container" style = {{ width: "5vw", height: "10vh", position: "relative" }}>
                                                <img className = "image" alt = "" src = { require("../../../img/carousel/space1.jpg") } style = {{ objectFit: "cover", width: "100%", height: "100%", position: "absolute", top: 0, left: 0, cursor: "pointer" }}/>
                                            </div>
                                            <div className = "col-md-1 col-lg-1 col-xl-1 col-xxl-1 image-container" style = {{ width: "5vw", height: "10vh", position: "relative" }}>
                                                <img className = "image" alt = "" src = { require("../../../img/carousel/space1.jpg") } style = {{ objectFit: "cover", width: "100%", height: "100%", position: "absolute", top: 0, left: 0, cursor: "pointer" }}/>
                                            </div>
                                            <div className = "col-md-1 col-lg-1 col-xl-1 col-xxl-1 image-container" style = {{ width: "5vw", height: "10vh", position: "relative" }}>
                                                <img className = "image" alt = "" src = { require("../../../img/carousel/space1.jpg") } style = {{ objectFit: "cover", width: "100%", height: "100%", position: "absolute", top: 0, left: 0, cursor: "pointer" }}/>
                                            </div>
                                            <div className = "col-md-1 col-lg-1 col-xl-1 col-xxl-1 image-container" style = {{ width: "5vw", height: "10vh", position: "relative" }}>
                                                <img className = "image" alt = "" src = { require("../../../img/carousel/space1.jpg") } style = {{ objectFit: "cover", width: "100%", height: "100%", position: "absolute", top: 0, left: 0, cursor: "pointer" }}/>
                                            </div>
                                            <div className = "col-md-1 col-lg-1 col-xl-1 col-xxl-1 image-container" style = {{ width: "5vw", height: "10vh", position: "relative" }}>
                                                <img className = "image" alt = "" src = { require("../../../img/carousel/space1.jpg") } style = {{ objectFit: "cover", width: "100%", height: "100%", position: "absolute", top: 0, left: 0, cursor: "pointer" }}/>
                                            </div>
                                            <div className = "col-md-1 col-lg-1 col-xl-1 col-xxl-1 image-container" style = {{ width: "5vw", height: "10vh", position: "relative" }}>
                                                <img className = "image" alt = "" src = { require("../../../img/carousel/space1.jpg") } style = {{ objectFit: "cover", width: "100%", height: "100%", position: "absolute", top: 0, left: 0, cursor: "pointer" }}/>
                                            </div>
                                            <div className = "col-md-1 col-lg-1 col-xl-1 col-xxl-1 image-container" style = {{ width: "5vw", height: "10vh", position: "relative" }}>
                                                <img className = "image" alt = "" src = { require("../../../img/carousel/space1.jpg") } style = {{ objectFit: "cover", width: "100%", height: "100%", position: "absolute", top: 0, left: 0, cursor: "pointer" }}/>
                                            </div>
                                            <div className = "col-md-1 col-lg-1 col-xl-1 col-xxl-1 image-container" style = {{ width: "5vw", height: "10vh", position: "relative" }}>
                                                <img className = "image" alt = "" src = { require("../../../img/carousel/space1.jpg") } style = {{ objectFit: "cover", width: "100%", height: "100%", position: "absolute", top: 0, left: 0, cursor: "pointer" }}/>
                                            </div>
                                            <div className = "col-md-1 col-lg-1 col-xl-1 col-xxl-1 image-container" style = {{ width: "5vw", height: "10vh", position: "relative" }}>
                                                <img className = "image" alt = "" src = { require("../../../img/carousel/space1.jpg") } style = {{ objectFit: "cover", width: "100%", height: "100%", position: "absolute", top: 0, left: 0, cursor: "pointer" }}/>
                                            </div>
                                            <div className = "col-md-1 col-lg-1 col-xl-1 col-xxl-1 image-container" style = {{ width: "5vw", height: "10vh", position: "relative" }}>
                                                <img className = "image" alt = "" src = { require("../../../img/carousel/space1.jpg") } style = {{ objectFit: "cover", width: "100%", height: "100%", position: "absolute", top: 0, left: 0, cursor: "pointer" }}/>
                                            </div>
                                            <div className = "col-md-1 col-lg-1 col-xl-1 col-xxl-1 image-container" style = {{ width: "5vw", height: "10vh", position: "relative" }}>
                                                <img className = "image" alt = "" src = { require("../../../img/carousel/space1.jpg") } style = {{ objectFit: "cover", width: "100%", height: "100%", position: "absolute", top: 0, left: 0, cursor: "pointer" }}/>
                                            </div>
                                            <div className = "col-md-1 col-lg-1 col-xl-1 col-xxl-1 image-container" style = {{ width: "5vw", height: "10vh", position: "relative" }}>
                                                <img className = "image" alt = "" src = { require("../../../img/carousel/space1.jpg") } style = {{ objectFit: "cover", width: "100%", height: "100%", position: "absolute", top: 0, left: 0, cursor: "pointer" }}/>
                                            </div>
                                            <div className = "col-md-1 col-lg-1 col-xl-1 col-xxl-1 image-container" style = {{ width: "5vw", height: "10vh", position: "relative" }}>
                                                <img className = "image" alt = "" src = { require("../../../img/carousel/space1.jpg") } style = {{ objectFit: "cover", width: "100%", height: "100%", position: "absolute", top: 0, left: 0, cursor: "pointer" }}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className = "col-md-3 col-lg-3 col-xl-3 col-xxl-3" style = {{ border: "solid 1px blue" }}>
                                        미리보기가 나올 공간
                                    </div>
                                </div>
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default GroupFile;