import React from 'react';
import Header from '../../../common/Header';

function GroupSetting() {
    return(
        <div>
            <Header />
            <div className = "container">
                <div className = "container">
                    <h4>그룹 설정</h4>
                    <div className = "row" style = {{ width: "100%", marginTop: "20px" }}>
                        <div className = "col-md-8 col-lg-8 offset-md-2 offset-lg-2">
                            <ul style = {{ listStyle: "none" }}>
                                <li style = {{ marginBottom: "10px", borderBottom: "solid 1px black" }}>+ 그룹에 초대</li>
                                <li>반복문 돌면서 보여질 그룹 예시 1</li>
                                <li>반복문 돌면서 보여질 그룹 예시 2</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default GroupSetting;