import React, { useState } from 'react';
import { Offcanvas } from 'react-bootstrap';
import { Link } from 'react-router-dom';

const Sidebar = () => {
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <img 
        alt = "" 
        src = { require("../img/menu/menu-logo.png") } 
        onClick = { handleShow } 
        style = {{ 
            width: "50px",
            cursor: "pointer"
        }}
      />

      <Offcanvas show={ show } onHide={ handleClose } placement="start">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Storage Menu</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <ul style = {{ listStyle: "none" }}>
            <li><Link to = "/files/personal" style = {{ color: "black", textDecoration: "none" }}>PersonalFile</Link></li>
            <li><Link to = "/files/group" style = {{ color: "black", textDecoration: "none" }}>GroupFile</Link></li>
            <li style = {{ color: "black", textDecoration: "none" }}>Album</li>
            <li style = {{ color: "black", textDecoration: "none" }}>MyPage</li>
            <li style = {{ color: "black", textDecoration: "none" }}>Setting</li>
          </ul>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};
export default Sidebar;