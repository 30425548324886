import { FormDataApi } from "./FormDataApi";

export const call = (api, method, request) => {
    let headers = "";

    if (FormDataApi.includes(api)) {
        headers = new Headers({});
    } else {
        headers = new Headers({
            "Content-Type": "application/json",
        });
    }

    // 로컬 스토리지에서 ACCESS TOKEN 가져오기
    const accessToken = localStorage.getItem("Access_Token");
    if (accessToken && accessToken !== null) {
        headers.append("Authorization", "Bearer " + accessToken);
    }

    let options = {
        headers: headers,
        method: method,
    };

    let url = "/storage" + api;
    // let url = "http://58.226.156.85/storage" + api;
    // let url = "http://localhost:8888/storage" + api;

    if (request && method === 'GET') {
        const params = new URLSearchParams(request).toString();
        url = `${url}?${params}`;
    }

    if (FormDataApi.includes(api)) {
        if (request && method !== 'GET') {
            options.body = request;
        }
    } else {
        if (request && method !== 'GET') {
            options.body = JSON.stringify(request);
        }
    }

    let temp;
    return fetch(url, options)
        .then((response) => {
            if (response.status === 403 && response.url === "/storage/User/UserCheck") {
                // if (response.status === 403 && response.url === "http://58.226.156.85/storage/User/UserCheck") {
            // if (response.status === 403 && response.url === "http://localhost:8888/storage/User/UserCheck") {
                window.location.href = "/user/login";
            }
            return response;
        })
        .then((response) =>
            response.json().then((json) => {
                if (!response.ok) {
                    return Promise.reject(json);
                }
                temp = json;
                return temp;
            })
        )
        .catch((error) => {
            if (error === null) { alert("에러발생") }
            if (error.status === 403 || error.status === undefined) {
                window.location.href = "/"; // redirect
                if (localStorage.getItem("Access_Token")) { localStorage.clear(); }
            } else if (error.error === "Login Failed") {
                alert("로그인에 실패하였습니다. (아이디, 비밀번호 재확인 필요)");
            } else if (error.error === "NOTUSER") {
                alert("로그인 권한이 존재하지 않습니다.(관리자 로그인 권한 수락 필요)");
            }
            return Promise.reject(error);
        });
};